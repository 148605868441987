import axios from 'axios';
import Vue from 'vue';




export const baseURL = 'https://new.egyptamassage.com/cp';
export const baseURL2 = 'https://new.egyptamassage.com/cp2';
export const baseUploadURL = baseURL + '/files/';
export const baseUploadURL2 = baseURL2 + '/files/';

export const HTTP = axios.create({
  baseURL: `${baseURL}/api/v1/`,
  //  headers: {
  //    // Authorization: `Bearer ${user.token}`,
  //    crossdomain: true ,
  //   //  Vary:'Origin'
  //  },
});
export const HTTP2 = axios.create({
  baseURL: `${baseURL2}/api/v1/`,
  //  headers: {
  //    // Authorization: `Bearer ${user.token}`,
  //    crossdomain: true ,
  //   //  Vary:'Origin'
  //  },
});
