<template>
  <div class="container-fluid welcome">
    <div style="padding: 42px 0;padding-bottom: 0px;">
      <div class="con-title text-center">
        <h2>{{$t('Our')}} <span>{{$t('Services')}}</span></h2>
        <p class="text-center">
          {{$t('os1')}}<br>
        <span style="color: red;">{{$t('FREE PARKING NEAR!!!')}}</span> <br>
          {{$t('os2')}}
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6" style="padding: 0;">
        <div class="ovrly">
          <!-- <img
            class="img-fluid"
            src="../../../assets/home-1.jpg"
            style="width: 100%;height: 500px;"
          /> -->
          <iframe
            style="width: 100%;margin-top: 52px;"
            height="315"
            src="https://www.youtube.com/embed/AxOqTBEtzhU"
            title="YouTube video player"
            frameborder="0"
            autoplay
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          <div class="overlay"></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="block-content wow fadeInRight animated animated">
          <div class="border-title">
            <h2
              style="color:#ffffff;background:#f26838 none repeat scroll 0% 0%"
            >
              {{ $t('About EGYPTA MASSAGE') }}
            </h2>
          </div>
          <p style="color:#3e454c;">
            {{ $t('w1') }}
          </p>
          <p style="color:#3e454c;">
            {{ $t('w2') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'welcome',
};
</script>

<style></style>
