<template>
  <div class="container-fluid" style="height: 100vh;overflow: hidden;">
    <div class="row">
      <div class="col-md-6 col-xs-12 text-center left-half">
        <router-link to="/first" class="btn btn-success  mx-3">
          Massage
          <p>Bascharage</p>
        </router-link>
      </div>
      <div class="col-md-6 col-xs-12 text-center right-half">
        <router-link to="/second" class="btn btn-info  mx-3">
          Spa
          <p>Walferdange</p>
        </router-link>

        <!-- <p>Coming soon</p> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
/* تعيين خلفية للنصف الأيسر من الصفحة */
.left-half {
  /* background-image: url('../assets/home1.jpg'); */
  /* background-size: 100% 100%;
  background-repeat: no-repeat; */
  background-color: #81ecec;
  height: 100vh;
  border-right: 5px solid #ccc; /* إضافة إطار على الجانب الأيمن */
}
.left-half:hover {
  transform: scale(1.2);
  transition: transform 0.5s ease;
}

/* تعيين خلفية للنصف الأيمن من الصفحة */
.right-half {
  /* background-image: url('../assets/home2.jpg'); */
  /* background-size: 100% 100%;
  background-repeat: no-repeat; */
  background-color: #fab1a0;
  height: 100vh;
  border-left: 5px solid #ccc; /* إضافة إطار على الجانب الأيسر */
}
.right-half:hover {
  transform: scale(1.2);
  transition: transform 0.5s ease;
}

.btn {
  margin-top: 45vh;
}
/* تفعيل التأثير عند مرور الماوس على الزر */
.btn:hover {
  transform: scale(1.2);
  transition: transform 0.5s ease;
}

@media screen and (max-width: 890px) {
  .container,
  .container-fluid,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    width: 100% !important;
  }

  .left-half {
    height: 50vh !important;
    border-right: none;

    border-bottom: 5px solid #ccc; /* إضافة إطار على الجانب الأيمن */
  }

  .right-half {
    height: 50vh !important;
    border-left: none; /* إضافة إطار على الجانب الأيسر */
  }
  .btn {
    margin-top: 20vh;
  }
}
</style>
