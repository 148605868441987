<template>
  <div class="container products">
    <div class="text-center mb-5" v-if="!service">
      <div class="border-title">
        <h2
          style="color: #ffffff; background: #f26838 none repeat scroll 0% 0%"
        >
          {{ $t("Our Services") }}
        </h2>
      </div>
    </div>
    <div class="row mb-5" v-if="list && list.length > 0">
      <div
        class="col-md-6 col-sm-12 col-xs-12 col-lg-3"
        v-for="item of list"
        :key="item.id"
      >
        <div class="ed-course-in">
          <a
            href="https://salonkee.lu/salon/egypta-massage-bereldange?lang=fr"
            target="_blank"
            class="course-overlay"
            v-if="item.id == 12 || item.id == 13"
          >
            <img class="mimg" :src="baseUploadURL + item.image" alt="" />
            <span>{{ $getByLang(item.name) }}</span>
          </a>

          <router-link
            v-else
            :to="$getLink() + 'service/' + item.slug"
            class="course-overlay"
          >
            <img class="mimg" :src="baseUploadURL + item.image" alt="" />
            <span>{{ $getByLang(item.name) }}</span>
          </router-link>
        </div>
      </div>
      <div class="col-md-6 col-sm-12 col-xs-12 col-lg-3">
        <div class="ed-course-in">
          <router-link :to="$getLink() + 'faqs'" class="course-overlay">
            <img class="mimg" src="../../../assets/4.jpg" alt="" />
            <span>{{ $t("Faqs") }}</span>
          </router-link>
        </div>
      </div>

      <div class="col-md-6 col-sm-12 col-xs-12 col-lg-3">
        <div class="ed-course-in">
          <router-link :to="$getLink() + 'packages'" class="course-overlay">
            <img class="mimg" src="../../../assets/6.jpg" alt="" />
            <span>{{ $t("OUR PACKAGES") }}</span>
          </router-link>
        </div>
      </div>

      <div class="col-md-6 col-sm-12 col-xs-12 col-lg-3">
        <div class="ed-course-in">
          <router-link :to="$getLink() + 'gallery'" class="course-overlay">
            <img class="mimg" src="../../../assets/7.jpg" alt="" />
            <span>{{ $t("gallery") }}</span>
          </router-link>
        </div>
      </div>

      <div class="col-md-6 col-sm-12 col-xs-12 col-lg-3">
        <div class="ed-course-in">
          <router-link :to="$getLink() + 'blog'" class="course-overlay">
            <img class="mimg" src="../../../assets/35.jpg" alt="" />
            <span>{{ $t("blog") }}</span>
          </router-link>
        </div>
      </div>

      <div class="col-md-6 col-sm-12 col-xs-12 col-lg-3">
        <div class="ed-course-in">
          <router-link :to="$getLink() + 'page/gifts'" class="course-overlay">
            <img class="mimg" src="../../../assets/25.jpg" alt="" />
            <span>{{ $t("Gifts") }}</span>
          </router-link>
        </div>
      </div>

      <div class="col-md-6 col-sm-12 col-xs-12 col-lg-3">
        <div class="ed-course-in">
          <router-link :to="$getLink() + 'team'" class="course-overlay">
            <img class="mimg" src="../../../assets/456.jpeg" alt="" />
            <span>{{ $t("Our Team") }}</span>
          </router-link>
        </div>
      </div>

      <div class="col-md-6 col-sm-12 col-xs-12 col-lg-3">
        <div class="ed-course-in">
          <router-link :to="$getLink() + 'page/terms'" class="course-overlay">
            <img class="mimg" src="../../../assets/741.jpeg" alt="" />
            <span>{{ $t("Terms and Conditions") }}</span>
          </router-link>
        </div>
      </div>

      <div class="col-md-6 col-sm-12 col-xs-12 col-lg-3">
        <div class="ed-course-in">
          <router-link
            :to="$getLink() + 'page/PrivacyPolicy'"
            class="course-overlay"
          >
            <img class="mimg" src="../../../assets/55.jpeg" alt="" />
            <span>{{ $t("PrivacyPolicy") }}</span>
          </router-link>
        </div>
      </div>

      <div class="col-md-6 col-sm-12 col-xs-12 col-lg-3">
        <div class="ed-course-in">
          <router-link :to="$getLink() + 'contactUs'" class="course-overlay">
            <img class="mimg" src="../../../assets/contact.jpg" alt="" />
            <span>{{ $t("Contact us") }}</span>
          </router-link>
        </div>
      </div>
    </div>

    <div class="text-center mb-5" v-if="!service">
      <div class="border-title">
        <h2
          style="color: #ffffff; background: #f26838 none repeat scroll 0% 0%"
        >
          {{ $t("Popular Massages") }}
        </h2>
      </div>
    </div>
    <div class="row" v-if="list2 && list2.length > 0">
      <div v-for="item of list2" :key="item.id" class="col-md-6">
        <div class="home-top-cour row">
          <!--POPULAR COURSES IMAGE-->
          <div class="col-md-3">
            <router-link :to="$getLink() + 'massage/' + item.slug">
              <img :src="baseUploadURL + item.image" alt=""
            /></router-link>
          </div>
          <!--POPULAR COURSES: CONTENT-->
          <div class="col-md-9 home-top-cour-desc">
            <router-link :to="$getLink() + 'massage/' + item.slug">
              <h3>{{ $getByLang(item.name) }}</h3>
            </router-link>
            <h4>
              <ul v-if="service" class="xxxs">
                <li v-for="itm of getPriceByService(item.prices)" :key="itm.id">
                  {{ itm.minutes }} minutes = {{ itm.price }}€
                  {{
                    itm.recommended == 1 ? "(" + $t("recommended") + ")" : ""
                  }}
                </li>
              </ul>
              <ul v-else class="xxxs">
                <li v-for="itm of item.prices" :key="itm.id">
                  {{ itm.minutes }} minutes = {{ itm.price }}€
                  {{
                    itm.recommended == 1 ? "(" + $t("recommended") + ")" : ""
                  }}
                  <p style="font-size: 10px;color: #666;line-height: 15px;">
                    {{ itm.services.map((el) => $getByLang(el.name)) }}
                  </p>
                </li>
              </ul>
            </h4>
            <p>
              {{ $textSlice($getByLang(item.body)) }}
            </p>
            <div class="hom-list-share">
              <ul>
                <li>
                  <!-- <router-link :to="$getLink() + 'booking/' + item.slug">
                    <i class="fa fa-bar-chart" aria-hidden="true"></i
                    >{{ $t('BOOK') }}
                  </router-link> -->
                  <a
                    href="https://salonkee.lu/salon/egypta-massage-bereldange?lang=fr"
                    target="_blank"
                  >
                    <i class="fa fa-bar-chart" aria-hidden="true"></i
                    >{{ $t("BOOK") }}
                  </a>
                </li>
                <li>
                  <router-link :to="$getLink() + 'massage/' + item.slug"
                    ><i class="fa fa-eye" aria-hidden="true"></i
                    >{{ $t("Details") }}</router-link
                  >
                </li>
                <li>
                  <router-link :to="$getLink() + 'gift/' + item.slug"
                    ><i class="fa fa-gift"></i>{{ $t("Gift") }}</router-link
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center" style="margin-top: 30px">
      <a class="btn btn-more" v-if="!noMore" @click="getMore">{{
        $t("More Massages")
      }}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "products",
  props: {
    list: [],
    list2: [],
    service: null,
  },
  data() {
    return {
      baseUploadURL: this.$baseUploadURL2,
      noMore: false,
      skip: 6,
    };
  },
  methods: {
    getMore() {
      this.$http2
        .get(
          `massage?skip=${this.skip}&limit=6${
            this.service ? "&service=" + this.service : ""
          }`
        )
        .then(
          (res) => {
            for (const item of res.data) {
              this.list2.push(item);
            }
            this.skip = this.skip + 6;
            if (res.data.length < 66) this.noMore = true;
          },
          (err) => {
            console.log(err);
          }
        );
    },
    getPriceByService(prices) {
      const list = [];
      for (const item of prices) {
        if (item.services.find((el) => el.id == this.service)) {
          list.push(item);
        }
      }
      return list;
    },
  },
};
</script>
