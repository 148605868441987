<template>
  <div class=" slider">
    <b-carousel
      id="carousel-1"
      :interval="4000"
      controls
      indicators
      background="#ababab"
      img-width="1024"
      class="slider1"
      style="text-shadow: 1px 1px 2px #333;max-width: 100%;"
    >
      <!-- max-height: 78vh; -->
      <!-- img-height="480"  -->
      <!-- Text slides with image -->
      <b-carousel-slide img-src="/image/slider/1.jpeg">
        <div class="slider-con">
          <h2>
            {{$t('Welcome to')}}
            <div style="color:black">Egypta Massage</div>
          </h2>
          <p style="color:black">
            {{$t('wt')}}
          </p>
        </div>
      </b-carousel-slide>

      <b-carousel-slide img-src="/image/slider/2.jpeg">
        <div class="slider-con">
          <h2>{{$t('We are Open')}}<span style="color:black">7/7 {{$t('days')}}</span></h2>
          <h1 style="color:black;font-size:20px">{{$t('From')}} 09:30 {{$t('to')}} 20:00</h1>
          <h1 style="color:red;font-size:30px">{{$t('Free Parking')}}</h1>
        </div>
      </b-carousel-slide>

      <b-carousel-slide img-src="/image/slider/3.jpeg">
        <div class="slider-con">
          <h2>{{$t('We are')}} <span style="color:black">{{$t('Offering Massages at')}}</span></h2>
          <p style="color:black">{{$t('Home, Hotel and at office')}}</p>
        </div>
      </b-carousel-slide>
    </b-carousel>
  </div>
</template>

<script>
export default {
  name: 'slider',
};
</script>

<style>

</style>
