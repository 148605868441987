import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/home.vue';
import firstHome from '../views/branch1/Home.vue';
import secondHome from '../views/branch2/Home.vue';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/first',
    name: 'first',
    component: () => import('../views/router.vue'),

    children: [
      {
        path: '/',
        name: 'firstHome',
        component: firstHome,
      },
      {
        path: 'service/:slug',
        name: 'service',
        component: () => import('../views/branch1/service.vue'),
        // beforeEnter: ifNotAuthenticated,
      },
      {
        path: 'allService',
        name: 'allService',
        component: () => import('../views/branch1/allservice.vue'),
        // beforeEnter: ifNotAuthenticated,
      },

      {
        path: 'purchasing',
        name: 'purchasing',
        component: () => import('../views/branch1/purchasing.vue'),
      },

      {
        path: 'massage/:slug',
        name: 'massage',
        component: () => import('../views/branch1/massage.vue'),
        // beforeEnter: ifNotAuthenticated,
      },
      {
        path: 'booking/:slug',
        name: 'booking',
        component: () => import('../views/branch1/booking.vue'),
        // beforeEnter: ifNotAuthenticated,
      },
      {
        path: 'gift/:slug',
        name: 'gift',
        component: () => import('../views/branch1/gift.vue'),
        // beforeEnter: ifNotAuthenticated,
      },
      {
        path: 'packages',
        name: 'packages',
        component: () => import('../views/branch1/allpackages.vue'),
        // beforeEnter: ifNotAuthenticated,
      },

      {
        path: 'packages/:slug',
        name: 'packagesOne',
        component: () => import('../views/branch1/packages.vue'),
        // beforeEnter: ifNotAuthenticated,
      },

      {
        path: 'page/:slug',
        name: 'page',
        component: () => import('../views/branch1/page.vue'),
        // beforeEnter: ifNotAuthenticated,
      },
      {
        path: 'contactUs',
        name: 'contactUs',
        component: () => import('../views/branch1/contactUs.vue'),
        // beforeEnter: ifNotAuthenticated,
      },

      {
        path: 'faqs',
        name: 'faqs',
        component: () => import('../views/branch1/faqs.vue'),
        // beforeEnter: ifNotAuthenticated,
      },

      {
        path: 'team',
        name: 'team',
        component: () => import('../views/branch1/team.vue'),
        // beforeEnter: ifNotAuthenticated,
      },

      {
        path: 'blog',
        name: 'blog',
        component: () => import('../views/branch1/blog.vue'),
        // beforeEnter: ifNotAuthenticated,
      },
      {
        path: 'gallery',
        name: 'gallery',
        component: () => import('../views/branch1/gallery.vue'),
        // beforeEnter: ifNotAuthenticated,
      },

      {
        path: 'bayDone/:screen/:id/:done',
        name: 'bayDone',
        component: () => import('../views/branch1/bayDone.vue'),
        // beforeEnter: ifNotAuthenticated,
      },

      {
        path: 'blog-single/:id',
        name: 'blog-single',
        component: () => import('../views/branch1/blog-single.vue'),
        // beforeEnter: ifNotAuthenticated,
      },

      {
        path: 'google-map',
        name: 'google-map',
        component: () => import('../views/branch1/google-map.vue'),
        // beforeEnter: ifNotAuthenticated,
      },
    ],
  },
  {
    path: '/second',
    name: 'second',
    component: () => import('../views/router.vue'),

    children: [
      {
        path: '/',
        name: 'secondHome',
        component: secondHome,
      },
      {
        path: 'service/:slug',
        name: 'twoservice2',
        component: () => import('../views/branch2/service.vue'),
        // beforeEnter: ifNotAuthenticated,
      },
      {
        path: 'allService',
        name: 'twoallService',
        component: () => import('../views/branch2/allservice.vue'),
      },

      {
        path: 'purchasing',
        name: 'purchasing',
        component: () => import('../views/branch2/purchasing.vue'),
      },

      {
        path: 'massage/:slug',
        name: 'twomassage',
        component: () => import('../views/branch2/massage.vue'),
        // beforeEnter: ifNotAuthenticated,
      },
      {
        path: 'booking/:slug',
        name: 'twobooking',
        component: () => import('../views/branch2/booking.vue'),
        // beforeEnter: ifNotAuthenticated,
      },
      {
        path: 'gift/:slug',
        name: 'twogift',
        component: () => import('../views/branch2/gift.vue'),
        // beforeEnter: ifNotAuthenticated,
      },
      {
        path: 'packages',
        name: 'twopackages',
        component: () => import('../views/branch2/allpackages.vue'),
        // beforeEnter: ifNotAuthenticated,
      },

      {
        path: 'packages/:slug',
        name: 'twopackagesOne',
        component: () => import('../views/branch2/packages.vue'),
        // beforeEnter: ifNotAuthenticated,
      },

      {
        path: 'page/:slug',
        name: 'twopage',
        component: () => import('../views/branch2/page.vue'),
        // beforeEnter: ifNotAuthenticated,
      },
      {
        path: 'contactUs',
        name: 'twocontactUs',
        component: () => import('../views/branch2/contactUs.vue'),
        // beforeEnter: ifNotAuthenticated,
      },

      {
        path: 'faqs',
        name: 'twofaqs',
        component: () => import('../views/branch2/faqs.vue'),
        // beforeEnter: ifNotAuthenticated,
      },

      {
        path: 'team',
        name: 'twoteam',
        component: () => import('../views/branch2/team.vue'),
        // beforeEnter: ifNotAuthenticated,
      },

      {
        path: 'blog',
        name: 'twoblog',
        component: () => import('../views/branch2/blog.vue'),
        // beforeEnter: ifNotAuthenticated,
      },
      {
        path: 'gallery',
        name: 'twogallery',
        component: () => import('../views/branch2/gallery.vue'),
        // beforeEnter: ifNotAuthenticated,
      },

      {
        path: 'bayDone/:screen/:id/:done',
        name: 'twobayDone',
        component: () => import('../views/branch2/bayDone.vue'),
        // beforeEnter: ifNotAuthenticated,
      },

      {
        path: 'blog-single/:id',
        name: 'twoblog-single',
        component: () => import('../views/branch2/blog-single.vue'),
        // beforeEnter: ifNotAuthenticated,
      },

      {
        path: 'google-map',
        name: 'twogoogle-map',
        component: () => import('../views/branch2/google-map.vue'),
        // beforeEnter: ifNotAuthenticated,
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  // base:  process.env.NODE_ENV === 'production'
  // ? '/test/'
  // : '/',
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
