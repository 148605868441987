import Vue from 'vue';
import { HTTP, HTTP2, baseUploadURL, baseUploadURL2 } from './axios';

Vue.prototype.$baseUploadURL = baseUploadURL;
Vue.prototype.$baseUploadURL2 = baseUploadURL2;

Vue.prototype.$http = HTTP;
Vue.prototype.$http2 = HTTP2;
Vue.prototype.$eventHub = new Vue();

if (!localStorage.lang) {
  localStorage.lang = 'en';
}
Vue.prototype.$getByLang = (text: any) => {
  if (localStorage.lang && text && JSON.parse(text)) {
    return JSON.parse(text)[localStorage.lang];
  } else {
    return '';
  }
};

Vue.prototype.$textSlice = (text: any, limit = 150) => {
  if (text) {
    return text.slice(0, limit) + '...';
  } else {
    return '';
  }
};

Vue.prototype.$getLink = () => {
  const link = location.href;
  if (link.search('/first') > -1) {
    return '/first/';
  }
  if (link.search('/second') > -1) {
    return '/second/';
  }
};
