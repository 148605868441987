<template>
  <div class="container-fluid say">
    <div class="say-overflow"></div>
    <div
      class="text-center mb-5"
      style="z-index: 99 !important; position: sticky"
    >
      <div class="border-title">
        <h2 style="color: #3e454c; background: #ffffff;padding: 2px 4px;">{{$t('Testimonials')}}</h2>
      </div>
    </div>

    <div class="container" v-if="list && list.length>0">
      <carousel
        :autoplay="true"
        :nav="false"
        :items="1"
        :loop="true"
      >
        <!--
            -->

        <div class="text-center" v-for="item of list" :key="item.id">
          <img :src="baseUploadURL + item.image" class="card-img-top" />
          <div class="test-inner-detail">
            <h4 style="color: #ffffff">{{ item.name }}</h4>

            <span style="color: #ffffff">{{ item.body }}</span>
          </div>
        </div>

        <template slot="prev"><span class="prev">prev</span></template>
        <template slot="next"><span class="next">next</span></template>
      </carousel>
    </div>
  </div>
</template>

<script>
import carousel from "vue-owl-carousel";

export default {
  name: "say",
  components: { carousel },
  props: {
    list: [],
  },
  data() {
    return {
      baseUploadURL: this.$baseUploadURL2,
      
    };
  },
  
};
</script>

<style></style>
