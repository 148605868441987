<template>
  <div v-if="loadx">
    <slider />
    <welcome />
    <products
      v-if="services && services.length > 0 && massage.length > 0"
      :list="services"
      :list2="massage"
      :service="false"
    />
    <say v-if="testimonials && testimonials.length > 0" :list="testimonials" />
    <section class="pt-120 pb-90">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-8">
            <div class="section-title">
              <span class="subtitle">{{$t('Team')}}</span>
              <h2>{{ $t('Our Team') }}</h2>
              <p>
                {{$t('ot1')}}<br />to
                {{$t('ot2')}}
              </p>
            </div>
          </div>
          <div class="col-md-4 text-lg-end"></div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-3 col-sm-6" v-for="item of userList" :key="item.id">
            <div class="single-specialist">
              <img :src="$baseUploadURL + item.image" data-rjs="2" alt="" />
              <div class="content">
                <h3>{{item.name}}</h3>
                <p>{{item.id == 1?'CEO':'Specialist Massage'}}</p>
                 <p v-if="item.id == 2">stagiaire</p>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </section>

    <!-- <div
      class="row isotopeContainer"
      id="container"
      style="position: relative; height: 494.1px;"
    >
      <div
        class="col-sm-3 air"
        style="position: absolute; left: 0px; top: 0px;"
        v-for="item in galleryList"
        :key="item.id"
      >
        <div class="card">
          <div class="card-img">
            <img
              class="img-full"
              :src="$baseUploadURL + item.image"
              alt="Image"
            />
            <div class="overlay-content"></div>
          </div>
        </div>
      </div>
    </div> -->

    <!-- <section class="pt-120 pb-90">
      <div class="container">
        <div class="row align-items-center">
          <div class="col">
            <div class="section-title">
              <span class="subtitle">News</span>
              <h2>Updating News</h2>
              <p>
                After owning two successful salon locations they decided<br />to
                move their passion to the small town.
              </p>
            </div>
          </div>
          <div class="col-md-4 text-lg-end"></div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-4 col-sm-12"
           v-for="item in blogList" :key="item.id"
          >
            <div class="single-post">
              <div class="post-thumb-wrap">
                <router-link :to="`/blog-single/`+item.id" class="post-thumbnail"
                  ><img :src="$baseUploadURL + item.image" data-rjs="2" alt=""
                /></router-link>
                <div class="posted-on">
                  <a href="#" class="posted"><i class="fa fa-comments-o" aria-hidden="true"></i>
                {{ item.blogcomment.length }}</a>
                </div>
              </div>
              <div class="post-content">
                <router-link :to="`/blog-single/`+item.id"
                  ><h3 class="post-title">{{ item.name }}</h3></router-link
                >
                <div class="post-summary">
                  <p>
                    {{ item.body.substr(0, 140) }}...
                  </p>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </section> -->
    <conactus />
  </div>
</template>

<script>
import slider from './home/slider';
import welcome from './home/welcome';
import conactus from './home/conactus';
import products from './home/products';
import say from './home/say';

export default {
  components: {
    slider,
    welcome,
    conactus,
    products,
    say,
  },
  data() {
    return {
      visible: false,
      services: [],
      massage: [],
      testimonials: [],
      loadx: false,
      galleryList: [],
      blogList: [],
      userList:[]
    };
  },

  created() {
    this.$http.get('homeData').then(
      async (res) => {
        this.services = await res.data.services;
        this.massage = await res.data.massage;
        this.testimonials = await res.data.testimonials;
        // this.galleryList = await res.data.galleryList;
        // this.userList = await res.data.userList;
        this.loadx = true;
        return await true;
      },
      (err) => {
        console.log(err);
      },
    );


     this.$http.get('users').then(
      async (res) => {
        
        this.userList = await res.data;
        
      },
      (err) => {
        console.log(err);
      },
    );

    this.$http.get('gallery').then(
      (res) => {
        this.galleryList = res.data;
      },
      (err) => {
        console.log(err);
      },
    );

    this.$http.get('blog').then(
      (res) => {
        this.blogList = res.data;
      },
      (err) => {
        console.log(err);
      },
    );
  },
};
</script>

<style scoped>
.section-title {
  padding-left: 45px;
  position: relative;
  margin-bottom: 56px;
}
.section-title .subtitle {
  font-size: 13px;
  text-transform: uppercase;
  display: inline-block;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  position: absolute;
  left: 10px;
  top: 44px;
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  line-height: 1;
  color: #81ecec;
  font-weight: bold;
}
.section-title h2 {
  margin-top: -4px;
  margin-bottom: 17px;
  color: #81ecec;
}
.section-title .subtitle::after {
  position: absolute;
  left: -40px;
  top: 44%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  content: '';
  width: 32px;
  height: 1px;
  background-color: #81ecec;
}

.pb-90 {
  padding-bottom: 90px;
}
.pt-120 {
  padding-top: 120px;
}

.single-specialist {
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
}

.single-specialist img {
  width: 100%;
  height: 50vh;
}

.single-specialist .content {
  text-align: center;
  padding-top: 12px;
}

.homeGallery .col-sm-3,
.homeGallery .col-sm-4 {
  padding: 0;
}

.single-post {
  margin-bottom: 30px;
  -webkit-box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.05);
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.05);
}
.single-post .post-thumb-wrap {
  position: relative;
}
.single-post .post-thumbnail img {
  width: 100%;
}

.single-post .post-thumb-wrap .posted-on {
  position: absolute;
  left: 30px;
  bottom: 15px;
  width: 50px;
  height: 50px;
  font-size: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-transform: uppercase;
  line-height: 1.19;
  letter-spacing: 1px;
}

.single-post .post-thumb-wrap .posted-on a {
  display: block;
  color: #fff;
  text-align: center;
}

.single-post .post-thumb-wrap .posted-on .day {
  display: block;
  font-size: 16px;
  letter-spacing: normal;
  font-weight: 600;
}

.single-post .post-content {
  padding: 33px 30px 40px;
}

.single-post .post-content .post-title {
  margin-bottom: 10px;
  -webkit-transition: 0.3s ease-in;
  -o-transition: 0.3s ease-in;
  transition: 0.3s ease-in;

  font-size: 1.5rem;
  color: #0f0d0d;
}
</style>
