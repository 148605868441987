<template>
  <div class="container-fluid conactus">
    <div class="row">
      <div class="col-md-5">
        <img class="img-fluid" src="../../../assets/home-contactus.png" />
      </div>
      <div class="col-md-7">
        <div class="">
          <h3 style="text-align: left;">
            {{ $t('Conact Us') }}
          </h3>
          <form @submit.prevent="recaptcha" method="post">
            <div class="row mb-3">
              <div class="col">
                <input
                  type="text"
                  style="height: 54px;padding: 0;"
                  class="form-control"
                  :placeholder="$t('Enter Name')"
                  v-model="body.name"
                />
              </div>
              <div class="col">
                <input
                  type="text"
                  style="height: 54px;padding: 0;"
                  class="form-control"
                  :placeholder="$t('Enter Phone')"
                  v-model="body.phone"
                />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col">
                <input
                  type="text"
                  style="height: 54px;padding: 0;"
                  class="form-control"
                  :placeholder="$t('Enter subject')"
                  v-model="body.subject"
                />
              </div>
              <div class="col">
                <input
                  type="text"
                  style="height: 54px;padding: 0;"
                  class="form-control"
                  :placeholder="$t('Enter Email')"
                  v-model="body.email"
                />
              </div>
            </div>
            <div class="form-group">
              <textarea
                class="form-control"
                :placeholder="$t('Enter text')"
                rows="6"
                v-model="body.message"
              ></textarea>
            </div>
            <div class="form-group">
              <button type="submit" class="btn btn-more">
                {{ $t('send') }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'conactus',
  data() {
    return {
      body: {
        name: null,
        email: null,
        subject: null,
        phone: null,
        message: null,
      },
    };
  },
  methods: {
    async recaptcha() {
      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded();

      // Execute reCAPTCHA with action "login".
      const token = await this.$recaptcha('login');

      // Do stuff with the received token.
      await this.save();
    },
    save() {
      if (
        this.body.name &&
        this.body.email &&
        this.body.subject &&
        this.body.phone &&
        this.body.message
      ) {
        this.$eventHub.$emit('showSpinner', true);
        this.$http.post(`contact/add`, this.body).then(
          (res) => {
            this.$eventHub.$emit('showSpinner', false);
            this.$swal.fire({
              icon: 'success',
              title: 'Success',
              text: this.$t('Success Done'),
              showConfirmButton: false,
              timer: 1500,
            });
            this.body = {
              name: null,
              email: null,
              subject: null,
              phone: null,
              message: null,
            };
          },
          (err) => {
            this.$eventHub.$emit('showSpinner', false);
            this.$swal.fire({
              icon: 'error',
              title: 'Error',
              text: this.$t('Something has gone wrong'),
              showConfirmButton: false,
              timer: 1500,
            });
          },
        );
      }
    },
  },
};
</script>

<style></style>
